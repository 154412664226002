import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

import Lightbox from "react-image-lightbox"

import "react-image-lightbox/style.css"

import { BsZoomIn as ZoomIcon } from "react-icons/bs"
import { BsSpotify as SpotifyIcon } from "react-icons/bs"
import { FaDeezer as DeezerIcon } from "react-icons/fa"
import { AiFillApple as AppleIcon } from "react-icons/ai"
import { AiFillAmazonSquare as AmazonIcon } from "react-icons/ai"
import { SiTidal as TidalIcon } from "react-icons/si"
import { SiYoutubemusic as YoutubeMusicIcon } from "react-icons/si"
import { AiFillYoutube as YoutubeIcon } from "react-icons/ai"

import "./index.scss"

export default function StreamPage({ data }) {
  const images = data.allFile.nodes

  const imagesTitles = ["Yuli front cover", "Yuli back cover"]

  // ~ isOpen
  const [isOpen, setIsOpen] = React.useState(false)

  // ~ indexes
  const [index, setIndex] = React.useState(0)
  const prevIndex = (index + images.length - 1) % images.length
  const nextIndex = (index + images.length + 1) % images.length

  // ~ full image urls
  // ce sont les url utilisés par Lightbox pour afficher les images en plein écran
  const mainSrc = images[index].childImageSharp.fullSize.images.fallback.src
  const nextSrc = images[nextIndex].childImageSharp.fullSize.images.fallback.src
  const prevSrc = images[prevIndex].childImageSharp.fullSize.images.fallback.src

  // ~ thumbnails urls
  // ce sont les urls utilisés par Lightbox pour afficher un thumbnail pendant que l'image se charge
  const mainSrcThumbnail =
    images[index].childImageSharp.thumbnail.images.fallback.src
  const nextSrcThumbnail =
    images[nextIndex].childImageSharp.thumbnail.images.fallback.src
  const prevSrcThumbnail =
    images[prevIndex].childImageSharp.thumbnail.images.fallback.src

  // ~ image counter
  // affiche la position de l'image / le nombre total d'images dans la barre supérieure
  const imageCounter = `${index + 1} / ${images.length}`

  return (
    <Layout>
      <Seo title="Yuli" index follow />
      <div className="container">
        <h1
          className="title text-center uppercase"
          style={{
            fontSize: "2.5rem",
            marginTop: "3.5rem",
            marginBottom: "3rem",
          }}
        >
          OZENKAWA & SACHA
        </h1>
        <div className="front-cover_container">
          <button onClick={() => setIsOpen(true)}>
            <StaticImage
              src="../images/cover_front.png"
              alt="Ozenkawa & Sacha album front cover"
              placeholder="blurred"
              className="front-cover container"
            />
            <ZoomIcon className="zoom-icon" color="white" size="15px" />
          </button>
        </div>
      </div>
      {/* Lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={mainSrc || ""}
          nextSrc={nextSrc || ""}
          prevSrc={prevSrc || ""}
          mainSrcThumbnail={mainSrcThumbnail || ""}
          nextSrcThumbnail={nextSrcThumbnail || ""}
          prevSrcThumbnail={prevSrcThumbnail || ""}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          imageTitle={imagesTitles[index]}
          // imageCaption={imagesCaptions[index]}
          nextLabel="Image suivante"
          prevLabel="Image précédente"
          zoomInLabel="Zoomer"
          zoomOutLabel="Dézoomer"
          closeLabel="Fermer"
          imageLoadErrorMessage="L'image sera disponible prochainement."
          toolbarButtons={[imageCounter]}
        />
      )}
      <div className="container streaming-links_container">
        <h2 className="title text-center uppercase italic">Stream</h2>
        <div className="streaming-link">
          <a
            href="https://open.spotify.com/album/34Es6UPMyTpkzvL9N8qP1Z"
            rel="noopener noreferrer"
            target="_blank"
          >
            <SpotifyIcon className="streaming-icon" /> Spotify
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://deezer.page.link/MV7ixW4zmUmUTqcm6"
            rel="noopener noreferrer"
            target="_blank"
          >
            <DeezerIcon className="streaming-icon" /> Deezer
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://music.apple.com/fr/album/yuli/1594454499?ls"
            rel="noopener noreferrer"
            target="_blank"
          >
            <AppleIcon className="streaming-icon" /> Apple Music
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://music.amazon.fr/albums/B09LH1PC16?ref=dm_sh_HgS2Kb620V1YiCu0qv1KLer16"
            rel="noopener noreferrer"
            target="_blank"
          >
            <AmazonIcon className="streaming-icon" /> Amazon Music
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://tidal.com/album/204501399"
            rel="noopener noreferrer"
            target="_blank"
          >
            <TidalIcon className="streaming-icon" /> Tidal
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://www.youtube.com/watch?v=IJ1NM0VD4pE"
            rel="noopener noreferrer"
            target="_blank"
          >
            <YoutubeMusicIcon className="streaming-icon" /> YouTube Music
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://youtu.be/pAxPE89GjuU"
            rel="noopener noreferrer"
            target="_blank"
          >
            <YoutubeIcon className="streaming-icon" /> YouTube
          </a>
        </div>
      </div>
      <h2 className="title text-center uppercase italic">Download</h2>
      <div className="container streaming-links_container">
        <div className="streaming-link">
          <a
            href="https://www.amazon.fr/Yuli-Ozenkawa-SACHA/dp/B09LH1PC16/ref=sr_1_34?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=379P7VX0IWUG5&keywords=ozenkawa&qid=1639869197&sprefix=ozenkawa%2Caps%2C140&sr=8-34"
            rel="noopener noreferrer"
            target="_blank"
          >
            <AmazonIcon className="streaming-icon" /> Amazon
          </a>
        </div>
        <div className="streaming-link">
          <a
            href="https://l.facebook.com/l.php?u=https%3A%2F%2Fmusic.apple.com%2Ffr%2Falbum%2Fyuli%2F1594454499%3Ffbclid%3DIwAR3RQgyv9fGG_hCtr1eSEkts9PGr7bVAKOQDHe_iyy2Bg0u4ukFzZSxsVE0&h=AT2CC7XFMY74tK8tZAIikMCPymSTP4fFANniElc0vEoK5o1PkROU-va7STNh_vhqpCfZE9O8md9Vhi8zm1rVVyVFSc_JD_u0vMkhz8KxaTPPwjo8UUCo3ARW9e4"
            rel="noopener noreferrer"
            target="_blank"
          >
            <AppleIcon className="streaming-icon" /> iTunes
          </a>
        </div>
      </div>
      <h2 className="title text-center uppercase italic">
        Support the artists
      </h2>
      <div className="album-credits container">
        <ul>
          <li>
            <p>Music</p>
            <a
              href="https://www.instagram.com/ozenkawa/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @OZENKAWA
            </a>
          </li>
          <li>
            <p>Lyrics</p>
            <a
              href="https://www.instagram.com/semsacha/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @SACHA
            </a>
          </li>
          <li>
            <p>Guitars, keyboards & drums</p>
            <a
              href="https://www.instagram.com/ozenkawa/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @OZENKAWA
            </a>
          </li>
          <li>
            <p>Vocals</p>
            <a
              href="https://www.instagram.com/semsacha/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @SACHA
            </a>
          </li>
          <li>
            <p>Violin</p>
            <a
              href="https://www.instagram.com/camille_philippon_art/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @camille_philippon_art
            </a>
          </li>
          <li style={{ color: "black" }}>
            <p>Recording, mix & mastering</p>
            <a
              href="https://www.instagram.com/bbmaj7/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @Jean-Étienne Loose
            </a>{" "}
            & Geoffroy Dauptain at{" "}
            <a
              href="https://www.studiotonehouse.fr/home"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @Tone House Studio
            </a>
          </li>
          <li>
            <p>Picture</p>
            <a
              href="https://www.instagram.com/camille_philippon_art/"
              rel="noopener noreferrer"
              target="_blank"
              className="insta-link"
            >
              @camille_philippon_art
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query albumCoversQuery {
    allFile(
      filter: { name: { regex: "/lighthouse/" } }
      sort: { fields: name, order: DESC }
    ) {
      nodes {
        name
        childImageSharp {
          fullSize: gatsbyImageData
          thumbnail: gatsbyImageData(width: 250, placeholder: BLURRED)
        }
      }
    }
  }
`
